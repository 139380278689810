import { classNames, SortDirection } from '@oshcut/components'
import { AccountStatementItem } from '@oshcut/oshlib'
import { DateTime } from 'luxon'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { FaFileArrowDown } from 'react-icons/fa6'
import { Link, Route, useHistory } from 'react-router-dom'
import Luxon from '../Luxon'
import OrderDetailsRouteWrapper from '../OrderDetailsRouteWrapper'
import './css/InvoiceTableMobile.scss'
import { BillingViewProps, formatStatementItemPrice, SortableColumn, SortInfo } from './shared'

type LocalProps = {
  statementItems: AccountStatementItem[]
  sortInfo: SortInfo | undefined
  setSortInfo: (sortInfo: SortInfo | undefined) => void
  checkboxMap: Set<string>
  checkboxMapUpdated?: (checkboxMap: Set<string>) => void
}
type Props = BillingViewProps & LocalProps

export const InvoiceTableMobile: React.FC<Props> = (props) => {
  const { statementItems, checkboxMap } = props

  const selectableItems = !!props.checkboxMapUpdated

  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (checkboxMap && props.checkboxMapUpdated) props.checkboxMapUpdated(checkboxMap)
  }, [checkboxMap])


  function setSortColumn(newSortColumn: SortableColumn) {
    if (newSortColumn !== props.sortInfo?.column) {
      //first click
      props.setSortInfo({ column: newSortColumn, direction: SortDirection.ASCENDING })
    } else {
      if (props.sortInfo?.direction === SortDirection.ASCENDING) {
        //second click
        props.setSortInfo({ column: newSortColumn, direction: SortDirection.DESCENDING })
      } else if (props.sortInfo?.direction === SortDirection.DESCENDING) {
        //third click
        props.setSortInfo(undefined)
      }
    }
  }

  function toggleCheckbox(id: string) {
    const newCheckboxMap = new Set(checkboxMap)
    if (newCheckboxMap.has(id)) {
      newCheckboxMap.delete(id)
    } else {
      newCheckboxMap.add(id)
    }
    props.checkboxMapUpdated?.(newCheckboxMap)
  }

  const history = useHistory()

  function handleClose() {
    history.push(`/account/invoices`)
  }

  return (
    <div className="InvoiceTableMobile">
      {error && <div className={'error'}>{error}</div>}
      <div className='compactInvoiceList'>

        {statementItems?.length ? statementItems.map((si, index) => {
          const dtNow = DateTime.fromMillis(Date.now())
          const dtDue = si.dueDate != null ? DateTime.fromMillis(si.dueDate) : null
          const dayDifference = dtDue != null ? Math.ceil(dtDue.diff(dtNow, 'days').days) : null
          const daysPastDue = (dayDifference != null && dayDifference < 0) ? Math.abs(dayDifference) : null
          const isPastDue = si.status === 'open' && daysPastDue && daysPastDue > 0
          return <div key={si.disambiguatedId} className={classNames('compactInvoiceItemWrapper', 'billing-item-row', { 'selected': checkboxMap.has(si.disambiguatedId) })}>

            {selectableItems ?
              <div className={classNames('invoiceItemCheckbox', { checked: checkboxMap.has(si.disambiguatedId) })} onClick={() => toggleCheckbox(si.disambiguatedId)}>
                <svg width="24px" height="24px" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>
              </div>
              :
              <div />
            }

            <div className='compactInvoiceItem'>

              <div className='invoiceName'>

                <div className='textwrap'>
                  {si.type === 'Legacy Invoice' ? 'Invoice' : si.type}: <b>{si.type === "Invoice" ? <a href={`/api/v3/sales_invoice/pdf?id=${si.id}`} target='_blank'>{si.name} <FaFileArrowDown /></a> : <a href={`/api/v1/order/invoice?id=${si.id}`} target="_blank">{si.name} <FaFileArrowDown /></a>}</b>
                </div>

              </div>

              <div className='textwrap'>
                Invoice date: <b>{si.invoiceDate != null && <Luxon format='D'>{si.invoiceDate}</Luxon>}</b>
              </div>

              {si.orderNumber && <div className='textwrap'>
                Order: <b><Link to={`/account/invoices/order/${si.orderGuid}`}>{si.orderNumber}</Link></b>
              </div>}

              {si.reference && <div className='textwrap'>
                Reference: <b>{si.reference}</b>
              </div>}

              {si.dueDate && <div className={classNames('textwrap', { 'past-due': isPastDue })}>
                Due Date: <b><Luxon format='D'>{si.dueDate}</Luxon> &nbsp; {isPastDue && daysPastDue !== null && `(${daysPastDue} day${daysPastDue > 1 ? 's' : ''} past due)`}</b>
              </div>}

              <div className='textwrap total-column'>
                Total: <b>{formatStatementItemPrice(si.totalCents)}</b>
              </div>

              <div className='textwrap balance-column'>
                {si.type === 'Credit Note' ? 'Remaining balance:' : 'Outstanding balance:'} <b>{formatStatementItemPrice(si.outstandingBalanceCents)}</b>
              </div>

            </div>

          </div>
        }) : <div className="emptyTable">No Invoices Found</div>}

      </div>
      <Route path={"/account/invoices/order/:guid"}>
        <OrderDetailsRouteWrapper
          state={props.state}
          dispatch={props.dispatch}
          onClose={handleClose}
        />
      </Route>
    </div>
  )
}
