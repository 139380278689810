import { Arcified, ArcifiedTube, ArcifiedUnknown } from "@oshcut/oshlib";
import { PartType } from "types";

/**
 * Returns true if a part's arcified object is defined and is a tube.
 * @param part The part or arcified object.
 * @returns True if the part is a tube.
 */
export function isTube(part: PartType | undefined): part is PartType<ArcifiedTube>
export function isTube(part: Arcified | ArcifiedTube | ArcifiedUnknown | undefined): part is ArcifiedTube
export function isTube(part: PartType | Arcified | ArcifiedTube | ArcifiedUnknown | undefined): boolean {
  if (!part) return false
  if ('arcified' in part) {
    return part.arcified?.type === 'tube'
  } else if ('type' in part) {
    return part.type === 'tube'
  } else {
    return false
  }
}

/**
 * Returns true if a part's arcified object is defined and is type sheet metal.
 * @param part The part or arcified object.
 * @returns True if the part is type sheet metal.
 */
export function isSheetMetal(part: PartType | undefined): part is PartType<Arcified>
export function isSheetMetal(part: Arcified | ArcifiedTube | ArcifiedUnknown | undefined): part is Arcified
export function isSheetMetal(part: PartType | Arcified | ArcifiedTube | ArcifiedUnknown | undefined): boolean {
  if (!part) return false
  if ('arcified' in part) {
    return !!part.arcified && part.arcified?.type !== 'tube' && part.arcified?.type !== 'unknown'
  } else if ('type' in part) {
    return part.type !== 'tube' && part.type !== 'unknown'
  } else {
    return false
  }
}

/**
 * Returns true if a part's arcified object is defined and is type unknown.
 * @param part The part or arcified object.
 * @returns True if the part is type unknown.
 */
export function isUnknown(part: PartType | undefined): part is PartType<ArcifiedUnknown>
export function isUnknown(part: Arcified | ArcifiedTube | ArcifiedUnknown | undefined): part is ArcifiedUnknown
export function isUnknown(part: PartType | Arcified | ArcifiedTube | ArcifiedUnknown | undefined): boolean {
  if (!part) return false
  if ('arcified' in part) {
    return part.arcified?.type === 'unknown'
  } else if ('type' in part) {
    return part.type === 'unknown'
  } else {
    return false
  }
}

