import { Input, Dialog, PrimaryButton, SecondaryButton, Select } from "@oshcut/components"
import { ArcifyDuplicateResult, fetchPost, LibraryPart } from "@oshcut/oshlib"
import { useEffect, useMemo, useRef, useState } from "react"
import { removeRecognizedExtensions } from "acceptedFileTypes"
import { getErrorText } from "../InvoicesView/OpenInvoices"
import './css/AddToPartLibraryDialog.scss'
import { FaCheck, FaTimes } from "react-icons/fa"
import { MdBookmarkAdd } from "react-icons/md"
import { CartPart, PartType } from "types"
import { getErrorItems } from "components/Part/getErrorItems"
import { WorkingIcon } from "components/WorkingIcon"
import { LibraryPartTagsEditor } from "./LibraryPartTags"

type PropType = {
  mode: 'hidden' | 'new' | 'revision'
  onLibraryPartSaved: (item: LibraryPart) => void,
  onClose: () => void,
  part: CartPart | PartType,
  requireErrorCheck: boolean
}

export function AddToPartLibraryDialog({ mode, onClose, onLibraryPartSaved, part, requireErrorCheck }: PropType) {

  const initialPartNumber = getInitialPartNumberAndRevision(part)

  const [partNumber, setPartNumber] = useState(initialPartNumber[0])
  const [partRevision, setPartRevision] = useState(initialPartNumber[1])
  const [tagOptions, setTagOptions] = useState<string[]>([])
  const [partTags, setPartTags] = useState<string[]>(part.libraryPart?.item.customer_tags.split(',').filter(tag => !!tag) ?? [])

  const [isNameAvailable, setIsNameAvailable] = useState<boolean | null>(null)
  const [isArchived, setIsArchived] = useState<boolean | null>(null)
  const [mostRecentRevision, setMostRecentRevision] = useState<string | null>(null)
  const [nameCheckError, setNameCheckError] = useState<string | null>(null)

  const searchTextTimeout = useRef<number | undefined>(undefined);

  useEffect(() => {
    (async () => {
      try {
        let tagsResponse = await fetchPost<{ tags: string[] }>('/api/v2/library_part/get_distinct_tags')
        setTagOptions(tagsResponse.tags)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  function handlePartNumberChange(newPartNumber: string, newPartRevision: string) {
    if (searchTextTimeout.current) {
      clearTimeout(searchTextTimeout.current)
    }
    setPartNumber(newPartNumber)
    setPartRevision(newPartRevision)

    setIsNameAvailable(null)

    searchTextTimeout.current = window.setTimeout(() => {
      checkNameAvailability(newPartNumber, newPartRevision)
    }, 500)
  }

  async function checkNameAvailability(partNumber: string, partRevision: string) {
    setNameCheckError(null)
    setIsNameAvailable(null)
    try {
      let result = await fetchPost<{ available: boolean, customer_archived: boolean, mostRecentRevision: string, mostRecentTags: string }>('/api/v2/library_part/check_part_number_availability', {
        customer_part_number: partNumber.trim(),
        customer_part_revision: partRevision.trim()
      })
      setIsNameAvailable(result.available)
      setMostRecentRevision(result.mostRecentRevision ?? null)
      setIsArchived(result.customer_archived)
      if (mode === 'new' && result.mostRecentTags) {
        setPartTags(result.mostRecentTags.split(','))
      } else {
        // Part tags should have been set with an initial value from the part
      }
    } catch (e) {
      setNameCheckError(getErrorText(e))
      return false
    }
  }

  useEffect(() => {
    checkNameAvailability(partNumber, partRevision)
  }, [])

  async function handleSubmit() {
    // Save part to library

    let partId = part.id
    if (mode === 'revision') {
      // Creating a new revision, so duplicate the part.
      try {
        let responseDuplicate = await fetchPost<ArcifyDuplicateResult>('/api/v2/arcify/duplicate', {
          partId,
          version: 'latest',
        })

        partId = responseDuplicate.newPartId
      } catch (e) {
        setNameCheckError(getErrorText(e))
        return 
      }
    }

    try {
      let result = await fetchPost<{ library_part: LibraryPart }>('/api/v2/library_part/upsert_part_number', {
        part_id: partId,
        customer_part_number: partNumber.trim(),
        customer_part_revision: partRevision.trim(),
        customer_tags: partTags.map(tag => tag.trim()).filter(tag => !!tag).join(','),
      })
      onClose()
      onLibraryPartSaved(result.library_part)
    } catch (e) {
      setNameCheckError(getErrorText(e))
      return 
    }
  }

  // Input validation
  let partNumberMessage
  let okToSave = false

  if (!partNumber.trim()) {
    partNumberMessage = <div className='partNumberMessage error'>Please enter a part number.</div>
  } else if (nameCheckError) {
    partNumberMessage = <div className='partNumberMessage error'>{nameCheckError}</div>
  } else if (isArchived) {
    partNumberMessage = <div className='partNumberMessage error'><FaTimes /> This part number has been archived.</div>
  } else if (mode === 'revision' && !partRevision) {
    partNumberMessage = <div className='partNumberMessage error'><FaTimes /> Please enter a revision.{mostRecentRevision ? ` (Previous revision: ${mostRecentRevision})` : ''}</div>
  } else if (isNameAvailable === false) {
    if (mode === 'new') {
      partNumberMessage = <div className='partNumberMessage error'><FaTimes /> This part number is already in use.{mostRecentRevision ? ` (Previous revision: ${mostRecentRevision})` : ''}</div>
    } else if (mode === 'revision') {
      partNumberMessage = <div className='partNumberMessage error'><FaTimes /> This revision number is already in use.{mostRecentRevision ? ` (Previous revision: ${mostRecentRevision})` : ''}</div>
    }
  } else if (isNameAvailable === true) {
    partNumberMessage = <div className='partNumberMessage success'><FaCheck /> This revision number is available.{mostRecentRevision ? ` (Previous revision: ${mostRecentRevision})` : ''}</div>
    okToSave = true
  } else if (isNameAvailable === null) {
    partNumberMessage = <div>Checking availability...</div>
  }

  const errors = useMemo(() => {
    if (!requireErrorCheck) return []
    if (!('arcified' in part)) return null
    let allErrors = getErrorItems(part)
    return allErrors?.filter(e => {
      // Don't allow any errors. Also do not allow pre-nests.
      return e.type === 'error' || e.key === 'multipleOuterContours'
    })
  }, [part])


  return (
    <Dialog open={mode !== 'hidden'} onClose={onClose} title={<span><MdBookmarkAdd /> {mode === 'new' ? 'Save to Part Library' : 'Create Revision'}</span>} className='AddToPartLibraryDialog'>
      {mode === 'new' ?
        <p>
          Saving this part to your part library will allow you to quickly add it to future orders using its <b>part number</b>.
        </p>
        :
        <p>
          Creating a revision will make a copy of this part in your part library with a new revision number.
        </p>
      }
      <p>
        Once you place an order with this part, you won't be able to edit this revision of the part again.
      </p>

      {!errors ? <>

        {/* Error check not complete */}
        <p><WorkingIcon key='working' /> Checking part for errors, please wait...</p>
        <section className='controls-ltr'>
          <SecondaryButton onClick={onClose}>Close</SecondaryButton>
        </section>

      </> : <>

        {errors.length > 0 ? <>

          {/* Errors found */}
          <p className='error'>
            <FaTimes style={{ position: 'relative', top: 2 }} /> This part cannot be saved to the part library due to the following issues:
          </p>
          <ul>
            {errors.map(item => <li className='error'>{item.header}</li>)}
          </ul>
          <p className='error'>
            Please resolve these issues before saving to the part library.
          </p>
          <section className='controls-ltr'>
            <SecondaryButton onClick={onClose}>Close</SecondaryButton>
          </section>

        </> : <>

          {/* No errors */}
          <div className='partNumberFields'>
            <label style={{ flex: 2 }}>
              {mode === 'new' ? <b>Enter a part number/name * </b> : 'Part number/name'}
              <Input type='text' placeholder='Part Name' value={partNumber} onChange={(e, parsed) => handlePartNumberChange(parsed, partRevision)} disabled={mode === 'revision'} />
            </label>
            <label style={{ flex: 1 }}>
              {mode === 'new' ? 'Revision (optional)' : <b>New Revision *</b>}
              <Input type='text' placeholder='Revision' value={partRevision} onChange={(e, parsed) => handlePartNumberChange(partNumber, parsed)} />
            </label>
          </div>
          {partNumberMessage}
              <br />
              <label style={{ flex: 1 }}>
                Tags (optional)
                <LibraryPartTagsEditor
                  options={tagOptions}
                  value={partTags}
                  setOptions={setTagOptions}
                  setValue={setPartTags}
                />
              </label>
          <section className='controls-rtl'>
            <PrimaryButton onClick={handleSubmit} disabled={!okToSave}><MdBookmarkAdd /> {mode === 'new' ? 'Save to Part Library' : 'Save Revision'}</PrimaryButton>
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          </section>

        </>}
      </>}

    </Dialog >
  )
}

function getInitialPartNumberAndRevision(part: CartPart | PartType): [string, string] {
  if (part.isLibraryPart && part.libraryPart?.item.customer_part_number) {
    return [part.libraryPart.item.customer_part_number, '']
  }

  let partName = part.name
  partName = removeRecognizedExtensions(partName)
  // Regex to match "<PART_NUMBER> (Rev. <PART_REVISION>)":
  let match = partName.match(/^(.*) \(Rev\. (.*)\)$/)
  if (match) {
    return [match[1], match[2]]
  } else {
    return [partName, '']
  }
}