import { MaterialSheet, MaterialTube, MaterialType } from "@oshcut/oshlib"

/**
 * Joins material sheets or tubes with material types
 * @param matObjects The objects to extend with the material types
 * @param matTypes The material types to join with the objects
 * @returns A new array of objects with the material types joined
 */
export function joinWithMaterialTypes<T extends MaterialSheet | MaterialTube>(matObjects: T[], matTypes: MaterialType[]): (T & MaterialType)[] {
  return matObjects.map(mat => {
    return joinMaterial(mat, matTypes)
  }).filter((mat): mat is (T & MaterialType) => mat !== undefined)
}

export function joinMaterial<T extends MaterialSheet | MaterialTube | undefined>(matObject: T, matTypes: MaterialType[]): (T & MaterialType) | undefined {
  if (!matObject) return undefined
  let matType = matTypes.find(t => t.material_id === matObject.material_id)
  if (!matType) return undefined
  return { ...matObject, ...matType }
}