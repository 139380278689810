import { PipeStatus } from "@oshcut/oshlib"


/** Returned from `/api/v2/pipe_status/for_order_parts_of_order` */
export type PipeStatusForOrderPart = {
  order_part_id: number
  order_part_qty: number
  pipe_status: PipeStatus[]
}

/** Returned from `/api/v2/pipe_status/for_order_parts_of_orders` */
export type PipeStatusForOrder = {
  order_id: number,
  pipe_statuses: PipeStatusForOrderPart[]
}

export type PartQtys = {
  inProcessQty: number
  finishedQty: number
  scheduledQty: number
  percentage: number
}

export function getPercentCompletionMulti(data: PipeStatusForOrderPart[]) {
  return data.map(getPercentCompletion)
}

/**
 * Get the total percentage of completion for a part, based on the ordered quantity and the pipe statuses.
 * @param orderedQty The ordered quantity of the part
 * @param pipeStatuses The pipe statuses for the part
 */
export function getPercentCompletion(data: PipeStatusForOrderPart) {

  // TODO: This can still give wrong numbers in the case of gross overproduction. For example, if the order is for 100
  // parts, and 200 parts are produced but are each halfway done, the progress bar will show 100% even though the 100
  // that were ordered are only 50% done. To fix this, we would have to start counting from the end of the pipe status
  // array and when we reach the ordered qty, stop accumulating the percentage--the rest of the parts don't count.

  const result = data.pipe_status.reduce((sums, ps) => {
    const newSums = { ...sums }

    let percentage = 0

    // First, get the total operations, not including shipping, scrap or done
    // Make sure it's never less than 1
    const totalOperations = Math.max(
      ps.status.filter(step => !["DONE", "SHIPPING", "SCRAP"].includes(step.operation)).length
      , 1)

    // Next look through the steps to get quantities and percentages
    ps.status.forEach((step, index) => {
      let indexMultipler = index / totalOperations

      if (!["DONE", "SHIPPING", "SCRAP", "UNRELEASED"].includes(step.operation)) {
        newSums.inProcessQty += step.qty
      }
      else if (["DONE", "SHIPPING"].includes(step.operation)) {
        newSums.finishedQty += step.qty
      }
      // If scrapped or unreleased, don't count this part toward the percentage
      // Scrap is at the end, so it would get a high multiplier and throw off the progress bar.
      else if (["SCRAP", "UNRELEASED"].includes(step.operation)) {
        indexMultipler = 0
      }

      percentage += step.qty * indexMultipler / data.order_part_qty * 100
    })

    newSums.percentage += percentage

    return newSums
  }, {
    inProcessQty: 0,
    finishedQty: 0,
    percentage: 0,
  })

  // Hide overproduction
  if (result.finishedQty > data.order_part_qty) {
    result.finishedQty = data.order_part_qty
  }
  if (result.inProcessQty + result.finishedQty > data.order_part_qty) {
    result.inProcessQty = data.order_part_qty - result.finishedQty
  }

  result.percentage = Math.min(result.percentage, 100);

  // Get deficit (scheduled)
  const scheduledQty = Math.max(data.order_part_qty - result.inProcessQty - result.finishedQty, 0)

  return {
    ...result,
    scheduledQty
  }
}

/**
 * Combine multiple percentages to get a total percentage. The total percentage is the sum of the percentages of each
 * part, weighted by the total quantity of each part.
 */
export function aggregatePercentages(parts: PartQtys[]) {
  let totalQty = 0
  let totalPercentage = 0
  let totalInProcess = 0
  let totalFinished = 0
  let totalScheduled = 0

  for (let qtys of parts) {
    totalQty += qtys.scheduledQty + qtys.inProcessQty + qtys.finishedQty
    totalPercentage += qtys.percentage * (qtys.scheduledQty + qtys.inProcessQty + qtys.finishedQty)
    totalInProcess += qtys.inProcessQty
    totalFinished += qtys.finishedQty
    totalScheduled += qtys.scheduledQty
  }

  return {
    inProcessQty: totalInProcess,
    finishedQty: totalFinished,
    scheduledQty: totalScheduled,
    percentage: totalQty ? totalPercentage / totalQty : 0
  }
}