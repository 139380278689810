import { MaterialSheet, MaterialTube, MaterialType } from '@oshcut/oshlib'
import config from 'config'
import { CatalogDispatchFunction, CatalogStateType } from './Catalog'
import './css/RecentMaterialsView.scss'
import { MaterialTubeThumbnail } from './MaterialTubeThumbnail'
import { getTubeName } from './TubeListView'

type PropType = {
  state: CatalogStateType
  dispatch: CatalogDispatchFunction
  materialSheets: MaterialSheet[]
  materialTubes: MaterialTube[]
  materialTypes: MaterialType[]
}

export function RecentMaterialsView({ state, dispatch, materialSheets, materialTubes, materialTypes }: PropType) {

  function handleClickMaterialSheet(materialSheet: MaterialSheet) {
    dispatch({
      type: 'setSelectedMaterialSheetId', id: materialSheet.id
    })
  }

  if (materialSheets?.length > 0) {
    return (
      <div className='recentMaterialsView'>
        <b className='header'>Recent materials</b>
        {materialSheets.map(materialSheet => {
          const materialType = materialTypes.find(t => t.material_id === materialSheet.material_id)
          if (!materialType) return null
          return (
            <div onClick={() => handleClickMaterialSheet(materialSheet)} key={materialSheet.id} className='link'>
              <img style={{ width: '40px' }} src={`${config.staticAssets}/images/materials/${materialSheet.image}`} />
              {materialSheet.sheet_name} {materialType.name}
            </div>
          )
        })}
      </div>
    )
  }
  else if (materialTubes?.length > 0 && !state.tubeSelectDisabled) {
    return (
      <div className='recentMaterialsView'>
        <b className='header'>Recent materials</b>
        {materialTubes.map(materialTube => {
          const materialType = materialTypes.find(t => t.material_id === materialTube.material_id)
          if (!materialType) return null
          return (
            <div onClick={() => dispatch({ type: 'setSelectedMaterialTubeId', id: materialTube.id })} key={materialTube.id} className='link'>
              <MaterialTubeThumbnail className='tubeThumbnail' materialTube={materialTube} />
              {getTubeName(materialTube)} {materialType.name}
            </div>
          )
        })}
      </div>
    )
  }
  else {
    return null
  }

}
