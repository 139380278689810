import React from 'react'
import { fetchPost, OrderLatestQuote } from '@oshcut/oshlib'
import Log from '../logs'
import { PartType } from 'types'
import { isSheetMetal, isTube } from 'partTypeGuards'


/*
// RFQ object in the following format
let rfq = {
  partsToQuote: [
    {id: 'part-id',
    qty: 1},
    {id: 'part-id',
    qty: 10}
  ],
  quoteAllMaterials: true,
  customQuantities: [1, 10, 50, 100]
}
 
NOTES:
  - Server will return an error if any part has an unspecified material or unspecfied units, in the part database
  - Regardless of whether "quoteAllMaterials" or "customQuantities" are set, the server will quote the quantities specified in partsToQuote,
    using the materials identified in the database.  Prices of parts in the cart depend on each-other, since the total cut cost per minute
    drops as the total cut time increases.  Setup costs are also distributed across all parts.
  - If quoteAllMaterials is set, then the system will provide prices for every part in partsToQuote, for each material (i.e. all parts in 0.1" 5052 AL, then
    all parts in 0.125" 5052 AL, and so on).  This flag can be used to get prices for all materials, for display in the material selector
  - If customQuantities is set, then the system will provide prices for every part in partsToQuote, for each quantity specified (i.e. all parts in qty 1,
    then all parts in qty 10, etc.)  Nested parts maintain their nested quantity (i.e. a nested qty of 5 and a custom quantity of 10 yields an actual quoted
    qty of 50)
  - quoteAllMaterials and customQuantities can both be set.
 
 
RESPONSE OBJECT
 
 
let response = [
  {
    // THE FOLLOWING FIELDS ARE ALWAYS RETURNED
    parentId: 'P-...', // ID of the part in the database.  Will match the "id" field sent in the rfq object
    id: 0, // Contour ID of the outer contour of the part.  Can be ignored for non-nested files
    parentQty: 1, // Part quantity.  Will match the "qty" field provided for this part in the quoting object
    quotedQty: 1, // Quoted quantity.  Will match parentQty, unless the file was a prenest, in which case quotedQty = parentQty * nestedQty
    priceEachCents: 1000, // Part price each.  This price uses the material set in the part database, and the quantity requested in partsToQuote[n].qty.  Also uses db and qty info for all other parts in the rfq to get total cut time and cost
 
    // THE FOLLOWING FIELDS ARE ONLY RETURNED IF quoteAllMaterials is set, and/or customQuantities were provided
    materials: [ 
      {
        sheet_id: 1,
        description: '0.004" Stainless Steel 17-7 hardened',
        prices: [
          {
            parentQty: 1,
            quotedQty: 1,
            priceEachCents: 1000
          }
        ]
      }
    ]
  }
]
*/


/**
 * 
 * @param {Number} invalidationTimestamp Acts as an identifier that, when changed, will request a new quote.
 */
export function useQuote(invalidationTimestamp: number, rfq?: { order_guid: string } | null) {

  const refTimeout = React.useRef<any>()
  const refMostRecentInvalidationTimestamp = React.useRef<number>()
  const [quote, setQuote] = React.useState<OrderLatestQuote | null>(null)

  React.useEffect(() => {
    refMostRecentInvalidationTimestamp.current = invalidationTimestamp
    setQuote(null)

    if (refTimeout.current) {
      clearTimeout(refTimeout.current)
    }

    refTimeout.current = setTimeout(async () => {
      let newQuote = await fetchQuote(rfq)
      if (refMostRecentInvalidationTimestamp.current === invalidationTimestamp) {
        setQuote(newQuote)
      }
    }, 500)

  }, [invalidationTimestamp])

  return [quote]
}

async function fetchQuote(rfq?: { order_guid: string } | null) {
  if (!rfq) {
    return null
  }

  let response
  try {
    response = await fetchPost<OrderLatestQuote>('/api/v3/order/quote', rfq)
    // response = await fetchPost('/api/v2/part/rfq', rfq)
  }
  catch (ex) {
    Log.error(ex)
    return null
  }

  return response
}

// Rules: You should invalidate if a part needing a quote changes, but you should only make the request when all parts needing quotes are also ready.

/**
 * Returns true if: the part is not deleted, the part's status is not an ERROR, the part has a material, and the part has units.
 * @param part 
 */
export function partNeedsQuote(part: Partial<Pick<PartType, 'arcified' | 'deleted' | 'status'>>) {

  const arcified = part.arcified

  let doesIt = !part.deleted
    && (part.status ?? '').indexOf('ERROR') === -1
    && !!part.arcified
    && (
      (isSheetMetal(arcified) && arcified.material)
      || (isTube(arcified) && arcified.materialTube)
    )
    && !!part.arcified.units

  return doesIt
}

/**
 * Returns true if the part's status is READY and the part's dfm status is COMPLETE.
 * @param {Part} part 
 */
export function partReadyForQuote(part: PartType) {
  return part.status === 'PART_STATUS_READY' && part.dfmStatus === 'DFM_STATUS_COMPLETE'
}