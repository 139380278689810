import { classNames, OshTable, OshTableColumn, OshTableFormat, OshTableHeader, OshTableRow, SortDirection } from '@oshcut/components'
import Luxon from '../Luxon'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { Route, useHistory, Link} from 'react-router-dom'
import OrderDetailsRouteWrapper from '../OrderDetailsRouteWrapper'
import { FaFileArrowDown } from 'react-icons/fa6'
import { BillingViewProps, formatStatementItemPrice, SortableColumn, SortInfo } from './shared'
import { AccountStatementItem } from '@oshcut/oshlib'
import { DateTime } from 'luxon'

type LocalProps = {
  statementItems: AccountStatementItem[]
  sortInfo: SortInfo | undefined
  setSortInfo: (sortInfo: SortInfo | undefined) => void
  checkboxMap: Set<string>
  checkboxMapUpdated?: (checkboxMap: Set<string>) => void
}
type Props = BillingViewProps & LocalProps

export const InvoiceTable: React.FC<Props> = (props) => {
  const { statementItems, checkboxMap } = props

  const selectableItems = !!props.checkboxMapUpdated

  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if(checkboxMap && props.checkboxMapUpdated) props.checkboxMapUpdated(checkboxMap)
  }, [checkboxMap])


  function setSortColumn(newSortColumn: SortableColumn) {
    if (newSortColumn !== props.sortInfo?.column) {
      //first click
      props.setSortInfo({ column: newSortColumn, direction: SortDirection.ASCENDING })
    } else {
      if (props.sortInfo?.direction === SortDirection.ASCENDING) {
        //second click
        props.setSortInfo({ column: newSortColumn, direction: SortDirection.DESCENDING })
      } else if (props.sortInfo?.direction === SortDirection.DESCENDING) {
        //third click
        props.setSortInfo(undefined)
      }
    }
  }

  const history = useHistory()

  function handleClose() {
    history.push(`/account/invoices`)
  }

  const allChecked = useMemo(() => {
    if (checkboxMap.size === 0) return false
    return checkboxMap.size === statementItems.length
  }, [checkboxMap, statementItems])

  const partiallyChecked = useMemo(() => {
    if (checkboxMap.size === 0) return false
    return checkboxMap.size < statementItems.length
  }, [checkboxMap, statementItems])

  function handleGroupCheckboxClick(e: React.ChangeEvent<HTMLInputElement>) {
    if (allChecked) {
      props.checkboxMapUpdated?.(new Set())
    } else {
      props.checkboxMapUpdated?.(new Set(statementItems.map(item => item.disambiguatedId)))
    }
  }

  function toggleCheckbox(id: string) {
    const newCheckboxMap = new Set(checkboxMap)
    if (newCheckboxMap.has(id)) {
      newCheckboxMap.delete(id)
    } else {
      newCheckboxMap.add(id)
    }
    props.checkboxMapUpdated?.(newCheckboxMap)
  }

  return (
    <div className="InvoiceTable">
      {error && <div className={'error'}>{error}</div>}
      <OshTable>
        <OshTableHeader>
          <OshTableFormat
            label={selectableItems && <input type='checkbox' ref={input => {
              if (input) {
                input.indeterminate = !allChecked && partiallyChecked
              }
            }} checked={allChecked} onChange={handleGroupCheckboxClick} />}
            width={selectableItems ? 4 : 1}
            className='checkbox-column-header'
          />

          <OshTableFormat label='Type' width={selectableItems ? 10 : 13} sortable onSort={() => setSortColumn('type')} sortDir={props.sortInfo?.column === 'type' ? props.sortInfo.direction : undefined} />

          <OshTableFormat label='Invoice Date' width={10} sortable onSort={() => setSortColumn('invoiceDate')} sortDir={props.sortInfo?.column === 'invoiceDate' ? props.sortInfo.direction : undefined} />

          <OshTableFormat label='Name' width={11} sortable onSort={() => setSortColumn('name')} sortDir={props.sortInfo?.column === 'name' ? props.sortInfo.direction : undefined} />

          <OshTableFormat label='Order' width={8} sortable onSort={() => setSortColumn('orderNumber')} sortDir={props.sortInfo?.column === 'orderNumber' ? props.sortInfo.direction : undefined} />

          <OshTableFormat label='Reference' width={13} sortable onSort={() => setSortColumn('reference')} sortDir={props.sortInfo?.column === 'reference' ? props.sortInfo.direction : undefined} />

          <OshTableFormat label='Due Date' width={20} sortable onSort={() => setSortColumn('dueDate')} sortDir={props.sortInfo?.column === 'dueDate' ? props.sortInfo.direction : undefined} />

          <OshTableFormat label='Invoice Total' width={12} className='total-column' sortable onSort={() => setSortColumn('totalCents')} sortDir={props.sortInfo?.column === 'totalCents' ? props.sortInfo.direction : undefined} />

          <OshTableFormat label='Outstanding Balance' width={12} className='balance-column' sortable onSort={() => setSortColumn('outstandingBalanceCents')} sortDir={props.sortInfo?.column === 'outstandingBalanceCents' ? props.sortInfo.direction : undefined} />
        </OshTableHeader>

        {statementItems?.length ? statementItems.map((si, index) => {
          const dtNow = DateTime.fromMillis(Date.now())
          const dtDue = si.dueDate != null ? DateTime.fromMillis(si.dueDate): null
          const dayDifference = dtDue != null ? Math.ceil(dtDue.diff(dtNow, 'days').days) : null
          const daysPastDue = (dayDifference != null && dayDifference < 0) ? Math.abs(dayDifference) : null
          const isPastDue = si.status === 'open' && daysPastDue && daysPastDue > 0
          const downloadUrl = getDownloadUrl(si)
          return <OshTableRow key={si.disambiguatedId} className={classNames('billing-item-row', { 'selected': checkboxMap.has(si.disambiguatedId) })}>
            <OshTableColumn>
              {selectableItems && <input style={{ flexShrink: 0 }} type='checkbox' checked={checkboxMap.has(si.disambiguatedId)} onChange={() => toggleCheckbox(si.disambiguatedId)} />}
            </OshTableColumn>

            <OshTableColumn><div className='type'>{si.type === 'Legacy Invoice' ? 'Invoice' : si.type}</div></OshTableColumn>

            <OshTableColumn>{si.invoiceDate != null && <Luxon format='D'>{si.invoiceDate}</Luxon>}</OshTableColumn>

            <OshTableColumn>{!!downloadUrl ? <a href={downloadUrl} target='_blank'>{si.name} <FaFileArrowDown /></a> : <div>{si.name}</div>}</OshTableColumn>

            <OshTableColumn><Link to={`/account/invoices/order/${si.orderGuid}`}>{si.orderNumber}</Link></OshTableColumn>

            <OshTableColumn>{si.reference}</OshTableColumn>

            <OshTableColumn className={classNames({'past-due': isPastDue})}>
              {si.dueDate != null && <><Luxon format='D'>{si.dueDate}</Luxon> &nbsp; {isPastDue && daysPastDue !== null && `(${daysPastDue} day${daysPastDue > 1 ? 's' : ''} past due)`}</>}
            </OshTableColumn>

            <OshTableColumn className='total-column'>{formatStatementItemPrice(si.totalCents)}</OshTableColumn>

            <OshTableColumn className='balance-column'>{si.isVoided ? 'VOIDED' : formatStatementItemPrice(si.outstandingBalanceCents)}</OshTableColumn>

          </OshTableRow>
        }) : <div className="emptyTable">No Invoices Found</div>}

      </OshTable>
      <Route path={"/account/invoices/order/:guid"}>
        <OrderDetailsRouteWrapper
          state={props.state}
          dispatch={props.dispatch}
          onClose={handleClose}
        />
      </Route>
    </div>
  )
}

function getDownloadUrl(si: AccountStatementItem): string | null {
  switch (si.type) {
    case 'Invoice':
      return `/api/v3/sales_invoice/pdf?id=${si.id}`
    case 'Legacy Invoice':
      return `/api/v1/order/invoice?id=${si.id}`
    case 'Credit Note':
      return `/api/v2/credit_note/pdf?id=${si.id}`
    default:
      const _exhaustiveCheck: never = si.type
      throw new Error()
  }
}
