import { CartPart, ParsedOrder } from 'types'
import './css/OrderPartsTableMobile.scss'
import { LibraryPart, OrderPart, PipeStatus } from '@oshcut/oshlib'
import { LinkButton, PrimaryButton } from '@oshcut/components'
import { useEffect, useMemo, useRef, useState } from 'react'
import { getPercentCompletion } from 'pipeStatus'
import OrderPartQtyBoxes from './OrderPartQtyBoxes'
import { MdAddShoppingCart, MdBookmark } from 'react-icons/md'
import { FaStar } from 'react-icons/fa'
import { PartEllipsisMenuActions } from './PartEllipsisMenuActions'
import { ItemToAdd } from 'util'

type OrderPartsTableMobileProps = {
  order: ParsedOrder
  cartPartsWithPipeStatus: (CartPart & { pipeStatus: PipeStatus[] | undefined })[]
  onAddToCart: (items: ItemToAdd[]) => void
  onSavedCardTitleUpdate: (newTitle: string) => void
  onToggleStar: (starred: boolean) => void
  onAddAllToCart: () => void
  onAddToPartLibrary: (libraryPart: LibraryPart, orderPart: OrderPart) => void
}

const OrderPartsTableMobile = ({ order, cartPartsWithPipeStatus, onAddToCart, onAddAllToCart, onSavedCardTitleUpdate, onToggleStar, onAddToPartLibrary }: OrderPartsTableMobileProps) => {

  const subtotal = useMemo(() => {
    return cartPartsWithPipeStatus.reduce((acc, cp) => acc + cp.total, 0)
  }, [cartPartsWithPipeStatus])

  const orderIsInProduction = !["draft", "saved", "cancelled", "quoted", "shipped"].includes(order.status)

  const [cartTitle, setCartTitle] = useState(order.description || 'Untitled Cart')
  const cartTitleInputRef = useRef<HTMLInputElement>(null)

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  useEffect(() => {
    if (isEditingTitle && cartTitleInputRef.current) {
      cartTitleInputRef.current.select()
    }
  }, [isEditingTitle, cartTitleInputRef])

  function saveTitleEdit() {
    if (!cartTitle.trim()) return
    setIsEditingTitle(false)
    onSavedCardTitleUpdate(cartTitle)
  }

  function cancelTitleEdit() {
    setIsEditingTitle(false)
    setCartTitle(order.description || 'Untitled Cart')
  }

  function handleTitleInputKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!cartTitle) return
    if (e.key === 'Enter') {
      onSavedCardTitleUpdate(cartTitle)
    }
  }

  const isSavedCart = !!(order.status === 'saved')
  const cartIsStarred = !!order.customer_starred

  return (
    <div className="OrderPartsTableMobile">

      {/* Cart Title (only applicable for 'saved' orders) */}
      {isSavedCart ? (<div className="cartTitle">

        <FaStar className={`star ${cartIsStarred ? 'active' : ''}`} onClick={_ => onToggleStar(!cartIsStarred)} />

        {!isEditingTitle ?
          <div className='title'>
            <div className={'cartTitle'}>{cartTitle}</div>
            <LinkButton onClick={_ => setIsEditingTitle(true)}>Edit</LinkButton>
          </div> :
          <div className="title editing">
            <input
              ref={cartTitleInputRef}
              value={cartTitle}
              onChange={(e) => { setCartTitle(e.target.value) }}
              onKeyDown={handleTitleInputKeydown}
            />
            <div className='titleEditActions'>
              <LinkButton onClick={saveTitleEdit}>Save</LinkButton>
              <LinkButton onClick={cancelTitleEdit}>Cancel</LinkButton>
            </div>
          </div>
        }

      </div>) : null}

      <div className="header">
        <PrimaryButton onClick={onAddAllToCart} className="addAllToCartButton"><MdAddShoppingCart /> Add All to Cart</PrimaryButton>
      </div>

      <div className="parts">


        {cartPartsWithPipeStatus.map((data, index) => {

          const isPart = data.id.indexOf('P-') === 0
          const isWorkOrderItem = !!data.pipeStatus
          const hasThumbnail = isPart || isWorkOrderItem

          const percentCompleteData = [getPercentCompletion({
            order_part_id: data.orderPart.id,
            order_part_qty: data.orderPart.qty,
            pipe_status: data.pipeStatus ?? []
          })]

          return (
            <div className="orderPart" key={data.orderPart.id}>

              <div className="name">
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {data.name} {data.isLibraryPart && <MdBookmark size={20} />}
                </span>

                {/* Actions */}
                <PartEllipsisMenuActions part={data} onAddToCart={onAddToCart} onAddToPartLibrary={onAddToPartLibrary} />

              </div>

              <div className="thumbAndDetails">
                {hasThumbnail &&
                  <div className='partImage'>
                    <img src={data.imgSrc}></img>
                  </div>
                }
                {isPart &&
                  <div className="details">
                    <div className='detail'>{data.size}</div>
                    <div className='detail'>{data.material}</div>
                    <div className='detail'>{data.finishing}</div>
                  </div>
                }
              </div>


              <div className="productionAndPriceAndQty">


                {isWorkOrderItem && orderIsInProduction ? <div className='production'>
                  <OrderPartQtyBoxes data={percentCompleteData} />
                </div>: null}

                <div className="priceAndQty">

                  <div>
                    <div className="label">Price</div>
                    <div className="content">{data.priceString}</div>
                  </div>

                  <div>
                    <div className="label">Qty</div>
                    <div className="content">{data.qty}</div>
                  </div>

                  <div>
                    <div className="label">Total</div>
                    <div className="content">{data.totalString}</div>
                  </div>

                </div>


              </div>
            </div>
          )
        })}

        {/* Costing rows */}
        <div className="orderPart">
          <div className="costing">
            <div className="label">Subtotal</div>
            <div className="content">${subtotal.toFixed(2)}</div>
          </div>
        </div>
        <div className="orderPart">
          <div className="costing">
            <div className="label">Tax</div>
            <div className="content">${order.tax.toFixed(2)}</div>
          </div>
        </div>
        <div className="orderPart">
          <div className="costing">
            <div className="label">Total</div>
            <div className="content">${order.total.toFixed(2)}</div>
          </div>
        </div>

      </div>


    </div>
  )
}

export default OrderPartsTableMobile