import { classNames, OshTableColumn, OshTableRow, Tooltip } from '@oshcut/components';
import { LibraryPart, OrderPart, PipeStatus } from '@oshcut/oshlib';
import { getPercentCompletion } from 'pipeStatus';
import { useMemo } from 'react';
import { FaBookmark } from 'react-icons/fa';
import { FaDownload } from 'react-icons/fa6';
import { CartPart } from 'types';
import { ItemToAdd } from 'util';
import './css/OrderPartsTableRow.scss';
import OrderPartQtyBoxes from './OrderPartQtyBoxes';
import { PartEllipsisMenuActions } from './PartEllipsisMenuActions';

type OrderPartsTableRowProps = {
  part: CartPart & { pipeStatus: PipeStatus[] | undefined }
  isInProduction: boolean
  onAddToCart: (itemsToAdd: ItemToAdd[]) => void
  onAddToPartLibrary: (libraryPart: LibraryPart, orderPart: OrderPart) => void
}

export function OrderPartsTableRow({ part, isInProduction, onAddToCart, onAddToPartLibrary }: OrderPartsTableRowProps) {

  const isWorkOrderItem = !!part.pipeStatus

  const percentCompleteData = useMemo(() => [getPercentCompletion({
    order_part_id: part.orderPart.id,
    order_part_qty: part.orderPart.qty,
    pipe_status: part.pipeStatus ?? []
  })], [part.orderPart.id, part.orderPart.qty, part.pipeStatus])

  const isPart = part.id.indexOf('P-') === 0
  const hasThumbnail = isPart || isWorkOrderItem

  return <>
    <OshTableRow className={classNames('OrderPartsTableRow', { 'isPart': isPart || isWorkOrderItem })}>

      {/* Part thumbnail */}
      <OshTableColumn>
        {hasThumbnail ? <Tooltip title={<div><img className='partImageTooltip' src={part.imgSrc} /></div>}  >
          <div className='partImage'>
            <span />
            <img src={part.imgSrc}></img>
          </div>
        </Tooltip> : null}
      </OshTableColumn>

      {/* Part Details */}
      <OshTableColumn>
        <div className={'partDetail'}>
          <div className={'partName'}>
            <span>{part.name}</span>
            {part.isLibraryPart && <FaBookmark />}
            {isPart && part.canDownload && <a href={`/api/v1/part/dxf?part_id=${part.id.replace('N-', 'P-')}&original=1&name=${encodeURIComponent(part.orderPart.name)}`} target="_blank"><FaDownload /></a>}
          </div>
          {isPart ? <div className='detail'>{part.size}</div> : null}
          {isPart ? <div className='detail'>{part.material}</div> : null}
          {isPart ? <div className='detail'>{part.finishing}</div> : null}
        </div>
      </OshTableColumn>

      {/* Pipe Status */}
      <OshTableColumn>
        {isWorkOrderItem && isInProduction ? <OrderPartQtyBoxes data={percentCompleteData} /> : null}
      </OshTableColumn>

      {/* Per Price */}
      <OshTableColumn>{part.priceString}</OshTableColumn>

      {/* Qty */}
      <OshTableColumn>{part.qty}</OshTableColumn>

      {/* Total Price */}
      <OshTableColumn>{part.totalString}</OshTableColumn>

      {/* Actions */}
      <OshTableColumn>
        <PartEllipsisMenuActions part={part} onAddToCart={onAddToCart} onAddToPartLibrary={onAddToPartLibrary} />
      </OshTableColumn>

    </OshTableRow>

  </>

}